import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'




class Whychoose extends React.Component {

  render() {
    return (
      <section className='whychoose cryptwall pt-100'>
        <div className="container">
          <div className="text-center">
            <h4 className="heading-h2 mw1030"><span className='bluecolor'>What Makes Coinsclone</span> the Best Cryptocurrency Wallet Development Company?</h4>
            <p className="pharagraph text-center">
            Coinsclone is a leading Cryptocurrency Wallet Development Company in the crypto industry, recognized for our innovative wallet solutions and extensive expertise. With over 7 years of experience as a pioneering cryptocurrency exchange development company, we excel in delivering exceptional Bitcoin wallet app development services tailored for emerging startups. We leverage cutting-edge technology to build a robust cryptocurrency wallet, enabling easy management and secure transfers of virtual assets. Our commitment to excellence has established Coinsclone as a trusted name, known for providing customized virtual currency  wallet development, that meet the specific needs of our clients. We ensure that every product we deliver is packed with advanced features and fortified with rigorous security measures.
            </p>
            <div className="row table-content mw1030 m-auto">
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 left-side">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/cryptowallet/why-choose-crypto-wallet.png"
                  alt="Why Choose Crypto Wallet Development"
                  width={475}
                />
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-12">
            <ul>
              <li><p>50+ skilled blockchain professionals.</p></li>
              <li><p>Timely, high-quality product delivery.</p></li>
              <li><p>Deployed 100+ wallets on various blockchains.</p></li>
              <li><p>Expertise in responsive and scalable wallets.</p></li>
              <li><p>Continuous technical and customer support.</p></li>
              <li><p>Efficient handling of queries and issues.</p></li>
            </ul>
            </div>
            
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default Whychoose