import React from 'react'



class UseCase extends React.Component {

  render() {

    return (
      <section className="lucrative pb-2 pt-100 mb-0">
        <div className="container">
        <h2 className="heading-h2 text-center">Our Cryptocurrency Wallet Development <span className="bluecolor">Use Cases</span></h2>
          <div className="factor usecase">
            <div className="revenue">
              <p>Personal Asset Management</p>
              <p>Investment and Trading</p>
              <p>Token Creation and Management</p>
            </div>
            <div className="revenue">
              <p>Business Transactions</p>
              <p>Decentralized Finance (DeFi) Applications</p>
              <p>Crowdfunding and ICOs</p>
            </div>
            <div className="revenue">
              <p>Integration with E-Commerce Platforms</p>
              <p>Smart Contract Interactions</p>
              <p>Dapps and Games</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default UseCase