import React from 'react'




class UserFriendly extends React.Component {

  render() {
    return (
      <section className="bgblue queries">
        <div className="container">
          <div className="text-center">
            <p className="pharagraph t-white head">
              Do you have more queries ? Feel Free to Discuss With Our Team.
            </p>
            <p className="pharagraph mb-0 t-white">
              We are here to Answer your queries
            </p>
            <a href="https://www.coinsclone.com/contact-us/" className="whitebtn mt-4">Talk To Our Experts</a>
          </div>
        </div>
      </section>
    )
  }
}

export default UserFriendly