import React from 'react'


const TypesOf = () => {

  return (
    <section className="typeof crypapp pt-100 mb-0">
      <div className='gray-bg'>
        <div className="container">
          <div className="row">
              <h2 className="heading-h2 text-center"><span className="bluecolor">Types of</span> Cryptocurrency Wallet We Create
              </h2>
              <p className="text-center">
              Our versatile types of crypto wallet solutions provide seamless cryptocurrency management across various platforms.
              </p>
          </div>
          <div className='row popular we-offer'>
              <div className='col-md-12 col-lg-3'>
                  <p className="head3">Mobile Wallet</p>
                  <p className='pharagraph'>We offer a portable and user-friendly Mobile Wallet solution for managing cryptocurrencies compatible with both iOS and Android devices. These wallets provide easy access to digital assets, secure transactions, and real-time updates from anywhere.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <p className="head3">Desktop Wallet</p>
                  <p className='pharagraph'>Our desktop wallets provide a robust and secure platform for managing cryptocurrencies from the computer. These wallets offer advanced security features and extensive functionality, including asset management and transaction capabilities.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <p className="head3">Web Wallet</p>
                  <p className='pharagraph'>The web wallet we provide enables your users to access and manage their cryptocurrencies directly from any web browser. These wallets offer user-friendly interfaces, making it easy to perform transactions without the need for additional software.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <p className="head3">Browser Extension Wallets</p>
                  <p className='pharagraph'>Our browser extension wallets integrate seamlessly with web browsers, allowing your users to manage their digital assets directly from their browsers. These wallets offer a convenient way to engage with decentralized services with robust security.</p>
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TypesOf