import React, { Component } from 'react'

class Addon extends Component {


  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">What's New</span> In Our</span> Cryptocurrency Wallet Development Solutions</h3>
            <p className="text-center">We develop a customizable crypto wallet with these advanced features to enhance functionality and provide an exceptional user experience.
            </p>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                Staking
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                Swapping
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                Chrome Wallet Extension
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                User Chat
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                Crypto Asset Conversion
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs6')} >
                Bulk Transfer
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs7')} >
                Payment Gateway Integration
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs8')} >
                dApps Support
                </li>
              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">Staking
                      </p>
                      <p className="pharagraph">Our wallet offers a staking option that lets users earn rewards by holding and staking their cryptocurrencies. This feature not only helps users grow their holdings but also enhances engagement and attracts more users to your wallet by providing an additional incentive.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="418px" height="273px" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/staking.png" alt="Staking" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">Swapping
                      </p>
                      <p className="pharagraph">With our digital wallet, users can effortlessly swap, sell, or purchase cryptocurrencies directly within the wallet interface. This eliminates the need for external exchanges, making transactions more convenient and secure by keeping everything in one place.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="306px" height="271px" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/swapping.png" alt="Swapping" />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">Chrome Wallet Extension
                      </p>
                      <p className="pharagraph">When you opt for our wallet, you’ll also receive a Chrome extension. This extension allows users to easily access their wallet from desktop or laptop computers, providing a seamless and integrated experience across different devices.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="314px" height="281px" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/chrome-wallet-extension.png" alt="Chrome Wallet Extension" />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">User Chat</p>
                      <p className="pharagraph">Our wallet includes a built-in chat feature, which facilitates real-time communication between users. This functionality helps to minimize misunderstandings and enhances security by allowing users to verify transactions and interact directly within the wallet.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="364px" height="286px" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/user-chat.png" alt="User Chat" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">Crypto Asset Conversion
                      </p>
                      <p className="pharagraph">Our wallet supports an asset conversion feature that enables users to convert fiat currency to cryptocurrencies and vice versa directly within the wallet. This streamlined process simplifies transactions and provides users with greater flexibility in managing their assets.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="265px" height="260px" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/crypto-asset-conversion.png" alt="Crypto Asset Conversion" />
                    </div>
                  </div>
                </div>
                <div id="tabs6" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">Bulk Transfer</p>
                      <p className="pharagraph">Our wallet features bulk transfer capabilities, allowing users to send digital assets to multiple recipients. This feature speeds up transactions and is ideal for group transfers or airdrops, making it more efficient to distribute assets to several addresses at once.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="277px" height="215px" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/bulk-transfer.png" alt="Bulk Transfer" />
                    </div>
                  </div>
                </div>
                <div id="tabs7" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">Payment Gateway Integration</p>
                      <p className="pharagraph">Our wallet supports seamless payment gateway integration, allowing users to make transactions directly from within the wallet. This feature simplifies the payment process for merchants and users alike, enhancing convenience and streamlining transactions.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="434px" height="310px" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/payment-gateway.png" alt="Payment Gateway Integration" />
                    </div>
                  </div>
                </div>
                <div id="tabs8" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <p className="heading-h4">dApps Support</p>
                      <p className="pharagraph">Our Bitcoin wallet software is compatible with dApps, enabling users to interact with a wide range of blockchain-based applications directly from their wallet. This support opens up new possibilities for managing assets and engaging with various services on the blockchain.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="434px" height="310px" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/dapps-support.png" alt="dApps Support" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon