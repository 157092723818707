import React from "react"

class SecurityFeature extends React.Component {

  render() {
    return (
      <section className="what-make tokenize fintech pt-100 mb-0">   
          <div className="container">
            <h3 className="heading-h2 text-center">
              <span className="d-block"><span className='bluecolor'>Security Features</span> Of Our</span> Cryptocurrency Wallet Development</h3>
              <div className='d-flex flex-wrap justify-content-center ind-std'>
                  <p>Biometric Authentication</p>
                  <p>Pin Code or Password Protection</p>
                  <p>Encrypted Transaction</p>
                  <p>Browser Detection Security</p>
                  <p>Anti Phishing Protocols</p>
                  <span></span>
                  <p>Key Management Server</p>
                  <p>Threat Protection</p>
                  <p>Database Encryption</p>
              </div>
          </div>
      </section>
    )
  }
}

export default SecurityFeature
