import React from "react"

class FeaturesOf extends React.Component {

  render() {
    return (

      <section className="benifor feawall pt-100 mb-0">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="d-block">
            <span className="bluecolor">Features Of</span> Our</span> 
            Cryptocurrency Wallet Development Solutions</h2>
            <p className="text-center">
            Our high-end crypto wallet development service comes with an extensive array of premium features.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square">
                <p className="head3">QR Code Scanner</p>
                <p className="pharagraph">
                Effortlessly conduct transactions by scanning QR codes with your wallet. This feature simplifies the payment process, reduces the chances of manual entry errors, and speeds up transaction times for a more efficient user experience.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Cross-Platform Compatibility</p>
                <p className="pharagraph">
                We guarantee Seamless and consistent user experience across multiple devices and operating systems. Our cross-platform compatibility ensures secured management and access to digital assets from desktops, mobile devices, and web browsers.
                </p>
              </div>
              <div className="square" >
                <p className="head3">High-Level Security</p>
                <p className="pharagraph">
                Safeguard digital assets with state-of-the-art security measures. Our wallets incorporate advanced encryption protocols and multi-factor authentication, providing robust protection against unauthorized access and potential breaches.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Push Notifications</p>
                <p className="pharagraph">
                Stay updated with instant notifications for all the activities accounts. This push notification system alerts your users to transaction updates, changes in account status, and other critical information in real time, keeping them well-informed.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Non-Fungible Token (NFT) Support</p>
                <p className="pharagraph">
                Your users can seamlessly manage and interact with non-fungible tokens within your wallet. This feature allows for secure storage, transaction handling, and easy access to your unique digital assets, including art, collectibles, and more.
                </p>
              </div>  
              <div className="square" >
                <p className="head3">Multiple Network Integration</p>
                <p className="pharagraph">
                Connect with various blockchain networks effortlessly. Our wallet supports a wide range of cryptocurrencies and platforms, facilitating smooth transactions and comprehensive asset management across different blockchain ecosystems.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Real-Time Analytics</p>
                <p className="pharagraph">
                Gain valuable insights into the cryptocurrency holdings and transactions. Our advanced tools help your users monitor market trends, track performance, and make informed decisions to optimize your investment strategy.
                </p>
              </div><div className="square" >
                <p className="head3">Multi-Signature Authentication</p>
                <p className="pharagraph">
                Increase transaction security with multi-signature authentication. This feature requires multiple approvals to authorize transactions, reducing the risk of unauthorized access and providing an extra safeguard for your user assets.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Automated Portfolio Management</p>
                <p className="pharagraph">
                Utilize tools for automated portfolio management to streamline tracking and optimization. This feature helps to rebalance and manage crypto investments, ensuring the portfolio remains aligned with the financial goals.
                </p>
              </div>
            </div>
          </div>
      </section>

    )
  }
}

export default FeaturesOf
