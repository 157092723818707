import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerSection from "../components/Cryptowallet/Banner";
import Futuristic from "../components/Cryptowallet/Futuristic";
import Whychoose from "../components/Cryptowallet/Whychoose";
import TechStack from "../components/Cryptowallet/TechStack";
import UserFriendly from "../components/Cryptowallet/UserFriendly";
import FaqSection from '../components/Cryptowallet/FaqSection';
import SecurityFeature from '../components/Cryptowallet/SecurityFeature';
import Addon from '../components/Cryptowallet/Addon';
import DevApproach from '../components/Cryptowallet/DevApproach';
import DevService from '../components/Cryptowallet/DevService';
import TypesOf from '../components/Cryptowallet/TypesOf';
import FeaturesOf from '../components/Cryptowallet/FeaturesOf';
import WeProvide from '../components/Cryptowallet/WeProvide';
import UseCase from '../components/Cryptowallet/UseCase';
import ProfitableRev from '../components/Cryptowallet/ProfitableRev';



const Cryptowallet = () => (  
  <section className="cryptoex">
    <Layout>
    <link rel="preload" fetchpriority="high" as="image" href="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/crypto-wallet-development-company.webp" type="image/webp"></link> 
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/cryptocurrency-wallet-development-company/" />
        <title>Cryptocurrency Wallet Development Company - Coinsclone</title>
        <meta name="description" content="Being a leading cryptocurrency wallet development company, Coinsclone offers safe, secure and customizable crypto wallet development solutions at affordable price" />
        <meta name="keywords" content="Cryptocurrency Wallet Development Company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cryptocurrency Wallet Development Company - Coinsclone" />
        <meta property="og:description" content="Being a leading cryptocurrency wallet development company, Coinsclone offers safe, secure and customizable crypto wallet development solutions at affordable price" />
        <meta property="og:url" content="https://www.coinsclone.com/cryptocurrency-wallet-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/cryptowallet/crypto-wallet-development.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Being a leading cryptocurrency wallet development company, Coinsclone offers safe, secure and customizable crypto wallet development solutions at affordable price" />
        <meta name="twitter:title" content="Cryptocurrency Wallet Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/cryptowallet/crypto-wallet-development.png" />
      </Helmet>
      <BannerSection />
      <div className="breadcrumb mb-0 wallet-banner">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Cryptocurrency Wallet Development Company </span>
        </div>
      </div>
      <Futuristic />
      <DevService />
      <TypesOf />
      <FeaturesOf />
      <SecurityFeature />
      <Addon />
      <WeProvide />
      <UseCase />
      <ProfitableRev />
      <DevApproach />
      <Whychoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Cryptowallet
