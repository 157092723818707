import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class ProfitableRev extends React.Component {

  render() {

    return (
      <section className="banner secureof pt-100">
        <div className='gray-bg'>
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Profitable Revenue Streams</span> Of Our Cryptocurrency Wallet Development</h2>
          <p className="text-center">As a leading Crypto Wallet Development Company, we provide a top-notch wallet solution that helps you reap enormous profits.</p>
          <div className="row table-content mw1030">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12 text-center" >
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/profitable-revenue.png"
                alt="Profitable Revenue Streams Of Our crypto wallet development"
                width={466}
                imgClassName='labtop'
              />
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <ul>
                <li>Transaction Fees</li>
                <li>Launch of a New Coin or Token</li>
                <li>Interest Rates</li>
                <li>Staking</li>
                <li>Exchange Services</li>
                <li>Affiliate Programs</li>
                <li>Premium Features</li>
                <li>Consult fee</li>
                <li>Advertising</li>
                <li>Withdraw</li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default ProfitableRev