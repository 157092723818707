import React from "react"

class WeProvide extends React.Component {

  render() {
    return (

      <section className="process-of weprovide pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="bluecolor">Ready To Launch</span> Crypto Wallet Clone Solutions We Provide
            </h2>
            <p className="text-center">
            Our extensive range of crypto wallet clone solutions is designed to meet the specific needs of diverse users and businesses.
            </p>
            <div className="d-lg-flex add-one">
              <div className="square" >
                <a href="https://www.coinsclone.com/contact-us/">
                <p className="head3">Crypto.com Wallet Clone </p>
                <p className="pharagraph">
                Our Crypto.com Wallet Clone is a custodial wallet that integrates advanced features for trading, staking, and managing cryptocurrencies. This high-security platform is designed to replicate the robust capabilities of the original, providing users with a comprehensive solution for their crypto needs.
                </p>
                </a>
              </div>
              <div className="square">
              <a href="https://www.coinsclone.com/contact-us/">
                <p className="head3">Zengo Wallet Clone</p>
                <p className="pharagraph">
                The Zengo Wallet Clone is a non-custodial wallet that emphasizes cutting-edge security and user-friendly design. It features advanced biometric authentication and multi-signature security, ensuring that users can manage their digital assets with both ease and enhanced protection.
                </p>
                </a>
              </div>
              <div className="square" >
                <a href="https://www.coinsclone.com/exodus-wallet-clone-script/">
                <p className="head3">Exodus Wallet Clone</p>
                <p className="pharagraph">
                With our Exodus Wallet Clone, users experience a non-custodial wallet that offers both desktop and mobile access. This wallet includes a built-in exchange, enabling seamless management and exchange of multiple cryptocurrencies through an intuitive and user-friendly interface.
                </p>
                </a>
              </div>
            </div>
            <div className="d-lg-flex add-two">
              <div className="square" >
              <a href="https://www.coinsclone.com/contact-us/">
                <p className="head3">Guarda Clone</p>
                <p className="pharagraph">
                The Guarda Clone is a versatile non-custodial multi-currency wallet designed for easy management of various cryptocurrencies. It boasts a user-friendly interface and integrated exchange features. With Guarda Clone, you can handle your digital assets securely and efficiently.
                </p>
                </a>
              </div>
              <div className="square" >
                <a href="https://www.coinsclone.com/trustwallet-clone-app-development/">
                <p className="head3">Trust Wallet Clone</p>
                <p className="pharagraph">
                Our Trust Wallet Clone provides a secure, non-custodial mobile wallet experience with decentralized staking and support for multiple cryptocurrencies. It offers an easy-to-use interface and robust security features, making it an ideal choice for managing various digital assets.
                </p>
                </a>
              </div>
            </div>
            <div className="d-lg-flex add-three">
              <div className="square" >
                <a href="https://www.coinsclone.com/metamask-wallet-clone-script/">
                <p className="head3">MetaMask Wallet Clone </p>
                <p className="pharagraph">
                The MetaMask Wallet Clone is a non-custodial browser extension wallet that mirrors the powerful capabilities of the original. It provides a secure platform for managing Ethereum-based tokens and interacting with decentralized applications (dApps) directly from the browser.
                </p>
                </a>
              </div>
              <div className="square">
                <a href="https://www.coinsclone.com/binance-web3-wallet-clone/">
                <p className="head3">Binance Web3 Wallet Clone</p>
                <p className="pharagraph">
                Our Binance Web3 Wallet Clone is a non-custodial wallet designed for seamless integration with the Binance ecosystem and Web3 applications. It offers a feature-rich and secure environment for managing digital assets and engaging with decentralized finance (DeFi) services.
                </p>
                </a>
              </div>
              <div className="square" >
                <a href="https://www.coinsclone.com/coinbase-wallet-clone/"> 
                <p className="head3">Coinbase Clone Wallet </p>
                <p className="pharagraph">
                The Coinbase clone is a non-custodial wallet that integrates smoothly with the Coinbase Exchange. It offers a secure and intuitive platform for managing various cryptocurrencies. With built-in features, it ensures effortless asset management and added convenience for your users.
                </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WeProvide
