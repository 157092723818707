import React,  { useEffect, useState } from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalHeader, MDBModalFooter } from 'mdbreact'
import { faSkype, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function ButtonComponent()  {

    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    }

    const [whatsappLink, setWhatsappLink] = useState('');
    const [whatsappwebLink, setWhatsappwebLink] = useState('');

    useEffect(() => {
      if (typeof window !== 'undefined') {
        const currentUrl = window.location.href;
        const whatsappMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentUrl}`;
        const link = `https://api.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappMessage)}`;
        setWhatsappLink(link);
      }
      if (typeof window !== 'undefined') {
        const currentwebUrl = window.location.href;
        const whatsappwebMessage = `Hi there! I am looking for your Services. Can you send me the Product Demo of ${currentwebUrl}`;
        const link = `https://web.whatsapp.com/send?phone=919500575285&text=${encodeURIComponent(whatsappwebMessage)}`;
        setWhatsappwebLink(link);
      }
    }, []);

    return (
      <div className="banner-btn">
        <MDBContainer className="site-modal">
        <MDBBtn className="bluebtn" onClick={toggle}>Free Trial</MDBBtn>
        <MDBModal isOpen={modal} toggle={toggle} centered>
          <MDBModalHeader toggle={toggle}>
            Start Your Free Trial Now
          </MDBModalHeader>

          <MDBModalFooter>
            <a className="whtsmodal mobile-hide" rel="noreferrer" target="_blank" href={whatsappwebLink} >
              <span><FontAwesomeIcon icon={faWhatsapp} /></span>
              <span>whatsapp</span>
            </a>
            <a className="whtsmodal mobile-on" rel="noreferrer" target="_blank" href={whatsappLink} >
              <span><FontAwesomeIcon icon={faWhatsapp} /></span>
              <span>whatsapp</span>
            </a>
            <a className="skypemodal" rel="noreferrer" target="_blank" href="skype:live:hello_20214?chat" >
              <span><FontAwesomeIcon icon={faSkype} /></span>
              <span>skype</span>
            </a>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
      </div>
    )
}

export default ButtonComponent