import React from 'react'



class DevService extends React.Component {


  render() {

    return (
      <section className="icon pt-100 secureof padremove">
        <div className="container">
          <h2 className="heading-h2 text-center"><span className='bluecolor d-block'>Our Exclusive</span> Cryptocurrency Wallet Development Services</h2>
          <p className='text-center'>Explore our extensive range of crypto wallet development services, designed to meet the varied demands of our clients</p>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/web3-wallet-development.png" alt="Web3 Wallet Development" />Web3 Wallet Development
                  </div>
                <p className="pharagraph">Our Web3 Wallet solution is tailored for seamless interaction with decentralized applications (dApps) and smart contracts within the Web3 ecosystem. Our wallet solution enables smooth integration with blockchain networks, ensuring users can manage their assets and engage with dApps effortlessly.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/defi-wallet-development.png" alt='DeFi Wallet Development' />
                DeFi Wallet Development
                </div>
                <p className="pharagraph">Our skilled developers specialize in creating DeFi Wallets that are fully interoperable with major platforms, offering secure and flexible solutions. In this wallet, we provide features such as yield farming, staking, and lending capabilities, ensuring seamless participation in the DeFi ecosystem.</p>
              </div>
            </div> 
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/multi-currency-wallet-development.png" alt='Multi-Currency Wallet Development' />
                Multi-Currency Wallet Development
                </div>
                <p className="pharagraph">Our multi-currency wallet software supports a wide range of cryptocurrencies, allowing users to manage diverse digital assets from a single platform. This versatile solution simplifies portfolio management, offering a unified interface for tracking and transacting with multiple cryptocurrencies.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/web-wallet-development.png" alt='Web Wallet Development ' />
                Web Wallet Development 
                </div>
                <p className="pharagraph">We offer a customizable web wallet solution equipped with essential plugins to protect and manage your assets effectively. The crypto web wallets we create are designed for ease of use, allowing users to securely manage their assets and perform transactions without the need for additional software</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/nft-wallet-development.png" alt='NFT Wallet Development' />
                NFT Wallet Development
                </div>
                <p className="pharagraph">Our NFT wallet software is crafted to securely non-fungible tokens and digital collectibles. Our cost-effective NFT wallet solutions support multiple chains, allowing users to store and manage their NFTs with ease and security. This feature-rich platform ensures a seamless experience for your users.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv mb-0'>
                <div className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/mpc-wallet-development.png" alt='MPC Wallet Development' />
                MPC Wallet Development
                </div>
                <p className="pharagraph mb-0">Our expert team creates MPC wallets using sophisticated coding techniques, ensuring enhanced security and collaboration for managing digital assets. This approach minimizes the risk of single points of failure and provides a robust solution for safeguarding high-value assets through security measures.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DevService